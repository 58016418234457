 <template>
	<div class="log-management" ref="logManagement">
		<div class="form-area" ref="form">
			<el-form
				:inline="true"
				:model="form"
				class="demo-form-inline"
				label-position="left"
				label-width="72px"
			>
				<el-form-item label="日志标题：">
					<el-input v-model.trim="form.title" placeholder="请输入日志标题"></el-input>
				</el-form-item>
				<el-form-item label="日志类型：">
					<el-select v-model="form.type" clearable placeholder="请选择日志类型">
						<el-option label="正常" :value="0"></el-option>
						<el-option label="失败" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="onSearch">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table ref="multipleTable" :data="tableData" style="width: 100%" stripe :height="tableHeight">
			<el-table-column prop="id" label="编号" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="title" label="	日志标题" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="method" label="操作方式" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="remoteAddr" label="操作ip地址" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="createBy" label=" 创建人" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="time" label="方法执行时间（ms）" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column label="日志类型" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{scope.row.type===0?"正常":"失败"}}</span>
                </template>
            </el-table-column>
			<el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button
						icon="el-icon-edit-outline"
						type="text"
						size="small"
						sort="primary"
						@click="onQuerylog(scope.row)"
					>查看详情</el-button>
					<el-button
						icon="el-icon-delete"
						type="text"
						size="small"
						sort="danger"
						@click="onDeletelog(scope.row)"
					>删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination" ref="pagination">
			<el-pagination
				background
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
				:page-size="form.pageSize"
				:current-page="form.currentPage"
				@size-change="onSizeChange"
				@current-change="onCurrentChange"
			></el-pagination>
		</div>
		<el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="40%">
			<div>
				<div class="body"></div>
				<logInfo ref="logInfo" :logId="logId" @onDialogClose="onDialogClose" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="default" size="small" @click="dialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

  <script>
// import addlog from "./components/addlog.vue";
import { getLogsPage, deleteLogs } from "@/api/lib/api.js";
import logInfo from "./components/logInfo";

export default {
	name: "logManagement",
	components: {
		logInfo,
	},
	data() {
		return {
			tableHeight: 0,
			total: 10,
			tableData: [],
			form: {
				current: 1,
				size: 10,
			},
			multipleSelection: [],
			title: "",
			logId: null,
			dialogVisible: false,
		};
	},
	methods: {
		// 计算表格高度
		computeHeight() {
			let wholeHeight = this.$refs.logManagement.clientHeight;
			let formHeight = this.$refs.form.clientHeight;
			let paginationHeight = this.$refs.pagination.clientHeight;
			this.tableHeight =
				wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
		},
		// 点击查询
		onSearch() {
			this.form.current = 1;
			this.getlogListByField(this.form);
		},
		//获取数据
		getlogListByField(data) {
			getLogsPage(data).then((res) => {
				if (res.code === 0) {
					this.tableData = res.data.records;
					this.total = res.data.total;
					if (!res.data.records.length && this.form.current !== 1) {
						//如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
						self.form.current -= 1;
						self.getFilesPage(this.form);
					}
				} else {
					this.tableData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			});
		},
		//查询
		onQuerylog(row) {
			this.title = "日志详情";
			this.logId = row.id;
			this.dialogVisible = true;
		},
		// 禁用|启用 用户
		onDeletelog(row) {
            const h = this.$createElement;
			this.$msgbox({
				title: "消息",
				type: "warning",
				message: h("p", null, [
					h("span", null, "确定删除 "),
					h("i", { style: "color: #059370" }, row.name),
					h("span", null, " ？"),
				]),
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			})
				.then(() => {
					deleteLogs(row.id).then((res) => {
						if (res.code === 0) {
							this.$message.success("删除成功");
							this.getlogListByField(this.form);
						} else {
							this.$message.error("删除失败");
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						showClose: true,
						message: "已取消删除",
					});
				});
        },
		// 当前页码改变
		onCurrentChange(page) {
			this.form.current = page;
			this.getlogListByField(this.form);
		},
		// 当前条数改变
		onSizeChange(size) {
			this.form.size = size;
			this.getlogListByField(this.form);
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 弹窗关闭时的回调
		onDialogClose(flag) {
			this.dialogVisible = false;
		},
	},
	created() {
		this.onSearch();
	},
	mounted() {
		this.$nextTick(() => {
			this.computeHeight();
			window.addEventListener("resize", this.computeHeight, false);
		});
	},
	destroyed() {
		window.removeEventListener("resize", this.computeHeight);
	},
};
</script>
<style lang="scss" scoped>
.log-management {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	padding: 16px 20px;
	box-sizing: border-box;
	.avatarImg {
		vertical-align: middle;
		border-radius: 50%;
	}
}

.theme-light {
	.log-management {
		background: #ffffff;
		box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
	}
}
</style>