<template>
   <div class="logInfo">
       <el-row :gutter="20">
           <el-col :span="6" class="title">编号：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.id}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">	日志标题：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.title}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">日志类型：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.type===0?"正常":"失败"}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">	应用标识：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.serviceId}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">	用户代理：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.userAgent}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">操作ip地址：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.remoteAddr}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">操作方式：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.method}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">提交数据：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.params}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">请求url</el-col>
           <el-col :span="18" class="content">{{this.logInfo.requestUri}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">异常信息：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.exception}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">方法执行时间：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.time}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">	创建人：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.createBy}}</el-col>
       </el-row>
       <el-row :gutter="20">
           <el-col :span="6" class="title">创建时间：</el-col>
           <el-col :span="18" class="content">{{this.logInfo.createTime}}</el-col>
       </el-row>
   </div>
</template>
<script>
import { getLogsById } from "@/api/lib/api.js";

export default {
    props: {
		logId: {
			type: String,
			default: null
		},
	},
  data() {
    return {
        logInfo:{
            id: "",
            type: 0,
            title: "",
            serviceId: "",
            createBy: "",
            remoteAddr: "",
            userAgent: "",
            requestUri: "",
            method: "",
            params: "",
            time: "",
            exception: "",
            status: 0,
            createTime: "",
        }
    };
  },
  created() {
      this.getLogsById()
  },
  methods: {
      getLogsById(){
          if(this.logId){
            getLogsById(this.logId).then(res=>{
                if(res.code===0){
                    this.logInfo=res.data;
                }
            })
          }
      }
  }
};
</script>

<style lang='scss' >
.logInfo{
    padding: 1rem;
}
     .el-row {
        margin-bottom: 15px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 0.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        .title{
            font-weight: bold;
        }
    }
</style>